.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  position: fixed;
  top: 0;
  right: 2rem;
  left: 0;
  width: 100%;
  z-index: 3;
  transition: all 0.3s ease-out;
}

.navlinks {
  z-index: 0;
  margin-left: auto;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.link {
  width: fit-content;
  margin-right: 5px;
  text-decoration: none;
  padding: 10px;
}

.active {
  border-bottom: 2px solid var(--orange);
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0.5px var(--orange);
}

.link:hover {
  color: var(--orange);
  cursor: pointer;
  transition: all 0.2s ease-in;
}

#menu__toggle {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.n-left {
  align-items: center;
  justify-content: left;
  display: flex;
  gap: 1rem;
}

.n-name {
  font-size: 1.6rem;
  font-weight: bold;
  /* margin-top: 0.5rem; */
  color: rgb(0, 0, 153);
}

.n-button {
  flex: 2;
  margin-top: -0.5rem;
}

@media screen and (max-width: 1000px) {
  .navbar {
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .navlinks {
    flex-wrap: wrap;
    margin: auto auto;
  }

}

/* media queries */
@media screen and (max-width: 500px) {
  #menu__toggle {
    display: inline;
    position: absolute;
    top: auto;
    bottom: auto;
    right: 2rem;
  }

  .show {
    transition-duration: .25s;
  }

  .hide {
    transform: translate(-150%);
    left: -100%;
    transition: all 0.25s ease-in-out;
  }

  .linkouter {
    position: absolute;
    top: -2rem;
    left: 0rem;
    width: 100vw;
    z-index: -1;
    height: 105vh;
    background-color: var(--orange);
    opacity: 0.5;
  }

  .navbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
  }

  .navlinks {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: -2rem;
    left: 0rem;
    min-width: 15rem;
    height: 105vh;
    list-style: none;
  }

  .navlinks .link {
    display: block;
    margin-left: 10%;
    padding: 12px 24px;
  }
}